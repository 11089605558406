<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile pt-5 pb-5"
    v-bind:class="headerClasses"
  >
    <div class="kt-header-mobile__logo poschodoch-mobile-header">
      <span class="kt-header-mobile__toggler poschodoch-mobile-logo">
        <img alt="Poschodoch" :src="headerLogo" />
      </span>
    </div>

    <div
      v-if="customerLogo"
      class="kt-header-mobile__logo poschodoch-mobile-spravca"
      style="padding-right: 9rem; "
    >
      <img
        alt
        :src="customerLogo"
        style="display: block; max-width: 100%; max-height: 95px; width: auto; height: auto;"
      />
    </div>
    <div class="kt-header-mobile__toolbar text-right">
      <span style="white-space: pre-line">
        {{
          printText +
            $i18n.t("LAYOUT.PRINT_HEADER.PRINT_DATE") +
            new Date().toLocaleDateString()
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTToggle from "@/assets/js/toggle.js";
import ApiService from "@/common/api.service";

export default {
  name: "KTHeaderPrint",
  data() {
    return {
      imgContent: "",
      printText: "",
    };
  },
  components: {},
  mounted() {
    new KTToggle(this.$refs["kt_header_mobile_topbar_toggler"], {
      target: "body",
      targetState: "kt-header__topbar--mobile-on",
      togglerState: "kt-header-mobile__toolbar-topbar-toggler--active",
    });

    // loading company info
    ApiService.setHeader();
    ApiService.get("Dashboard/CompanyInfo").then(({ data }) => {
      this.imgContent = data.LogoContent;
    });

    // loading unit info
    ApiService.setHeader();
    ApiService.get("Dashboard/UnitInfo").then(({ data }) => {
      this.printText = data.PrintText + "\n";
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      if (window.location.host.includes("enbra")) {
        return (
          process.env.BASE_URL + this.layoutConfig("self.logo-enbra.poschodoch")
        );
      }
      if (window.location.host.includes("penta")) {
        return (
          process.env.BASE_URL + this.layoutConfig("self.logo-penta.poschodoch")
        );
      }
      if (window.location.host.includes("poschodech")) {
        return (
          process.env.BASE_URL + this.layoutConfig("self.logo-cz.poschodoch")
        );
      }

      return process.env.BASE_URL + this.layoutConfig("self.logo.poschodoch");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    customerLogo() {
      return "data:image/png;base64," + this.imgContent;
    },
  },
};
</script>
