<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile class="no-print"></KTHeaderMobile>
    <KTHeaderPrint class="do-print" style="display: none"></KTHeaderPrint>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTHeaderPrint from "@/views/theme/header/HeaderPrint.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/htmlclass.service";
//import KTSubheader from "@/views/theme/subheader/Subheader.vue";
//import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/store/htmlclass.module.js";

export default {
  name: "Print",
  components: {
    //  KTAside,
    KTHeader,
    KTHeaderMobile,
    KTHeaderPrint,
    KTFooter,
    //KTSubheader,
    //KTStickyToolbar,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.replace({ name: "login" });
    }

    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--enabled");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--fixed");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-aside--minimize");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background-enbra");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "login-background-penta");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--fixed");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--enabled");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-subheader--solid");
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-brand-light");
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-brand-dark");
    this.removeBackground();

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 2000);
  },
  methods: {
    removeBackground() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-3");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-enbra-3");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-1");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-2");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "login-background-penta-3");
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      if (window.location.host.includes("enbra")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-enbra");
      }
      if (window.location.host.includes("penta")) {
        return process.env.BASE_URL + this.layoutConfig("loader.logo-penta");
      }
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
